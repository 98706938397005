<template>
  <v-app-bar class app flat color="background">
    <v-app-bar-nav-icon @click.prevent="$emit('left-nav-toggle')"></v-app-bar-nav-icon>
    <v-toolbar-title class="text-xs-right pa-0" v-if="$route.meta.nav && $route.meta.nav.showBackButton === true">
      <v-layout align-baseline>
        <v-btn icon text small  class="mr-2" @click="$router.go(-1)">
          <v-icon size="18">fas fa-arrow-left</v-icon>
        </v-btn>
        {{$route.meta.nav?$route.meta.nav.name : ''}}
      </v-layout>
    </v-toolbar-title>
    <v-toolbar-title v-else class="text-xs-right">
        {{$route.meta.nav?$route.meta.nav.name : ''}}
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-toolbar-title class="subheading pr-3">
      <span class="color--grey">Welcome</span>
      {{vg_user.first_name}} {{vg_user.last_name}}
    </v-toolbar-title>

    <v-menu
      :close-on-content-click="true"
      :nudge-bottom="50"
      :nudge-left="150"
      :nudge-width="100"
      attach
      max-height="400"
      class="top-nav-select-business"
    >
      <template v-slot:activator="{ on }">
        <v-btn elevation="0" color="primary" v-on="on">
          <i class="fas fa-user-circle"></i>
          &nbsp; {{vg_activeBusiness.name}}
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item @click.prevent.stop="userSettingsModal.status = true">
            <v-list-item-action>
              <v-icon light>account_circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.prevent.stop="logout">
            <v-list-item-action>
              <v-icon light>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <p class="overline pa-0 ma-0 py-4 px-2">Your Businesses</p>
          <template v-for="(business, index) in vg_businessesList">
            <v-list-item :key="index" ripple @click="switchBusiness(business)">
              <v-list-item-action v-if="vg_activeBusiness.id === business.id">
                <v-icon color="primary">done</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ business.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </v-menu>
    <user-settings-modal
      v-if="userSettingsModal.status"
      @close-modal="userSettingsModal.status = false"
      :status="userSettingsModal.status"
      :user="vg_user"
      :profile="true"
    />
    <!-- <v-btn icon @click.stop="$emit('right-nav-toggle')">
      <v-icon>more_vert</v-icon>
    </v-btn>-->
  </v-app-bar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthAPI } from '../../clients/auth/auth.api'
import { EventBus } from '@/event-bus'

export default {
  data: () => {
    return {
      userSettingsModal: {
        status: false,
        user: null
      }
    }
  },
  components: {
    UserSettingsModal: () => import('@/views/Users/UserSettingsModal')
  },
  methods: {
    ...mapActions({
      va_setActiveBusiness: 'auth/setActiveBusiness',
      va_setActiveBusinessBranding: 'auth/setActiveBusinessBranding',
      va_clearAuthState: 'auth/clearState'
    }),
    async logout() {
      try {
        await AuthAPI.logout()
      } catch (error) {
        return
      }
      this.va_clearAuthState()
      this.swal({
        title: 'Success',
        text: 'Logout successful',
        type: 'success'
      })
      this.$router.push({ name: 'Login' })
    },
    async switchBusiness(business) {
      this.spinner(true)
      try {
        await this.va_setActiveBusiness(business)
      } catch (error) {
        this.spinner(false)
        return
      }
      try {
        await this.va_setActiveBusinessBranding(business.id)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.$vuetify.theme.themes.light.primary = this.vg_activeBusinessBrandingAndLogo.primary
      this.$vuetify.theme.themes.light.secondary = this.vg_activeBusinessBrandingAndLogo.secondary
      this.$vuetify.theme.themes.light.accent = this.vg_activeBusinessBrandingAndLogo.accent

      if (this.$route.name !== 'Applications') {
        this.$router.push({ name: 'Applications' })
      }
      console.log(this.$route)
      EventBus.$emit('fetch-mounting-methods')

      this.spinner(false)
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user',
      vg_businessesList: 'auth/businessesList',
      vg_activeBusiness: 'auth/activeBusiness',
      vg_activeBusinessBrandingAndLogo: 'auth/activeBusinessBrandingAndLogo'
    })
  }
}
</script>
